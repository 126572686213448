<template>
    <div class="main">
        <Title >工作环境</Title>
        <div class="img_box">
            
            <div class="col3" v-for="item in developDevData" :key="item.img">
             <ImagePreview  class="img1 hvr-grow" :src="require('../../assets/Talents/develop'+item.img)" :srcList="[require('../../assets/Talents/develop'+item.img)]">{{item.title}}</ImagePreview>
            </div>

        </div>
        <More></More>
        <!-- <ul class="box">
            <li class="list"  :key="item.title" v-for="(item,index) in developData">
                <div class="left" :style="'order:'+index%2==0&&0||1">
                    <p class="time">{{item.time}}</p>
                    <h3 class="title">{{item.title}}</h3>
                    <section class="content">
                        {{item.content}}
                    </section>
                    <el-button>查看详情 ></el-button>
                </div>
                <div class="right" :style="'order:'+index%2==0&&1||0" @click.stop="()=>false">
                    <ImagePreview  class="img2" :src="require('../../assets/Talents/develop'+item.img)" :srcList="[require('../../assets/Talents/develop'+item.img)]"></ImagePreview>
                    <img class="img" :src="require('../../assets/Talents/develop'+item.img[0])" />
                </div>
            </li>
        </ul> -->
    </div>
</template>
<script>
export default {
    data(){
        return {
        }
    },
    computed:{
        developDevData(){
            return this.$store.state.developDevData;
        },
        developData(){
            return this.$store.state.developData;
        }
    },
    methods:{
        detail(){
            this.$router.push({
                path:'/talents/develop_detail/1'
            })
        }
    }
}
</script>
<style src="../../layout.css" scoped></style>
<style scoped>

.main{
    width:80vw;
    max-width:1600px;
    margin:0 auto;
}
.img_box{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.img1{
    height: 22vw;
    max-height: 281px;
    margin-bottom: 2rem;
    min-height: 200px;
    width:90%
}

.box{
    margin:2rem 0;
    padding:0;
}
.list:hover{
    background:#284c91;
    
}
.list:hover .title{
    color:#fff;
}
.list:hover .content{
    color:#fff;
}
.list{
    display:flex;
    justify-content:space-between;
    list-style:none;
    border:1px solid #284c91;
    padding:1rem;
    margin-bottom:2rem;
    flex-wrap:wrap;
    align-items: center;
}
.time{
    font-size:0.7rem;
}
.title{
    margin:1rem 0;
    font-weight: 400;
    font-style: normal;
    font-size: 1.7rem;
    color: #025B82;
}
.content{
    font-size:0.9rem;
    color: #333333;
    margin: 3rem;
    
}
.left{
    max-width:600px;
    min-width:200px;
    text-align: left;
    width:27vw;
    align-self: baseline;
    padding: 1rem 0 1rem 2rem;
}
.img2{
    width:45vw;
    max-width:800px;
}
@media screen and (max-width: 1026px){
    .left{
        max-width:auto;
        width: 100%;
    }
    .img{
        margin:1rem;
    }
    .right{
        display: flex;
        justify-content: center;
        width: 100%;
    }
}
</style>